
/*
Put all theme based cutom jquery code here :)  Happy days!
*/

jQuery(document).ready(function() {
    (function($) {
        $( "input.gform_button.button" ).addClass( "button-primary large" );


    })

    (jQuery);


});


jQuery(function ($) {
    $(document).ready(function() {
        var swi = $('.service-with-image');
        var lwi = $('.location-with-image');
        var pwi = $('.post-with-image');
        var bgi = $('.with-bg-image');

        swi.each(function(i){
            if(i===1) // If 2nd item then add the class
                $(swi[i] ).find(".highlight-image").addClass('small-order-1 medium-order-2 large-order-2');
            $(swi[i] ).find(".highlight-content").addClass('small-order-2 medium-order-1 large-order-1');
            if(i===3) // If 4th item then add the class
                $(swi[i] ).find(".highlight-image").addClass('small-order-1 medium-order-2 large-order-2');
            $(swi[i] ).find(".highlight-content").addClass('small-order-2 medium-order-1 large-order-1');
            if(i===5) // If 6th item then add the class
                $(swi[i] ).find(".highlight-image").addClass('small-order-1 medium-order-2 large-order-2');
            $(swi[i] ).find(".highlight-content").addClass('small-order-2 medium-order-1 large-order-1');
            if(i===7) // If 6th item then add the class
                $(swi[i] ).find(".highlight-image").addClass('small-order-1 medium-order-2 large-order-2');
            $(swi[i] ).find(".highlight-content").addClass('small-order-2 medium-order-1 large-order-1');
            if(i===9) // If 6th item then add the class
                $(swi[i] ).find(".highlight-image").addClass('small-order-1 medium-order-2 large-order-2');
            $(swi[i] ).find(".highlight-content").addClass('small-order-2 medium-order-1 large-order-1');
        }),
        lwi.each(function(i){
            if(i===1) // If 2nd item then add the class
                $(lwi[i] ).find(".location-image").addClass('small-order-1 medium-order-2 large-order-2');
                $(lwi[i] ).find(".location-content").addClass('small-order-2 medium-order-1 large-order-1');
            if(i===3) // If 4th item then add the class
                $(lwi[i] ).find(".location-image").addClass('small-order-1 medium-order-2 large-order-2');
                $(lwi[i] ).find(".location-content").addClass('small-order-2 medium-order-1 large-order-1');
            if(i===5) // If 6th item then add the class
                $(lwi[i] ).find(".location-image").addClass('small-order-1 medium-order-2 large-order-2');
                $(lwi[i] ).find(".location-content").addClass('small-order-2 medium-order-1 large-order-1');
            if(i===7) // If 8th item then add the class
                $(lwi[i] ).find(".location-image").addClass('small-order-1 medium-order-2 large-order-2');
                $(lwi[i] ).find(".location-content").addClass('small-order-2 medium-order-1 large-order-1');
        }),
        pwi.each(function(i){
            if(i==1) // If 2nd item then add the class
                $(pwi[i] ).find(".highlight-image").addClass('order-md-2');
                $(pwi[i] ).find(".highlight-content").addClass('order-md-1');
            if(i==3) // If 4th item then add the class
                $(pwi[i] ).find(".highlight-image").addClass('order-md-2');
                $(pwi[i] ).find(".highlight-content").addClass('order-md-1');
            if(i==5) // If 6th item then add the class
                $(pwi[i] ).find(".highlight-image").addClass('order-md-2');
                $(pwi[i] ).find(".highlight-content").addClass('order-md-1');
        }),
        bgi.each(function(i){
            if(i==1) // If 2nd item then add the class
                $(bgi[i] ).find(".background-image").addClass('order-md-2');
                $(bgi[i] ).find(".background-content").addClass('order-md-1');
            if(i==3) // If 4th item then add the class
                $(bgi[i] ).find(".background-image").addClass('order-md-2');
                $(bgi[i] ).find(".background-content").addClass('order-md-1');
            if(i==5) // If 6th item then add the class
                $(bgi[i] ).find(".background-image").addClass('order-md-2');
                $(bgi[i] ).find(".background-content").addClass('order-md-1');
        }),
            $('img.svg').each(function(){
                var $img = $(this);
                var imgID = $img.attr('id');
                var imgClass = $img.attr('class');
                var imgURL = $img.attr('src');

                $.get(imgURL, function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's ID to the new SVG
                    if(typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if(typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass+' replaced-svg');
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Check if the viewport is set, else we gonna set it if we can.
                    if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
                    }

                    // Replace image with new SVG
                    $img.replaceWith($svg);
                }, 'xml');
            });

    });

});
